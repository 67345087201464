import * as React from "react"
import {IconBaseProps} from "react-icons";
const CkSwordTwoHanded = (props: IconBaseProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      height: props.size ?? 24,
      width: props.size ?? 24,
    }}
    viewBox="0 0 384 512"
    {...props}
  >
    <path
      d="M122.1 17.52 186.6 161l104.5-44.9-44.5-98.58h-51.1l35.8 80.75-16.4 7.23-39-87.98zm265.2 77.75L110.4 215.2l12 28.2 278.1-119.8zm-69.7 98.53-23.9 11.5c12.8 5.4 26 13.7 38.3 15.6 18.1-13.1-4.9-30.5-14.4-27.1m-60.7 11.1-36.2 16.7c-11.9 18.4-14.8 46-18.2 69.7 20.6 30.1 30.5 62.4 39.5 94 17.9-3.7 32.2-6.8 48.7-11.5-24.4-38.7-42.2-78.4-53.6-118.9 0 0 5.6-10.5 8.4-15.8 11.9 7.3 24.9 12.4 36.6 9.9 10-4.1 21.5-7.9 27.7-16.6-17.6-6.9-37.1-17.5-52.9-27.5m92.6 26.6c-10.8 5.8-23 11.2-31.9 18.9-5.3 4.7-12.1 8.8-20.5 12.3 1.1 7.3-.1 19.6 8.1 18.8 20.5-6.2 40.5-15 59.7-28.4-.1-9.2-8-24.1-15.4-21.6m-90.2 34.2c11.3 34.8 27.6 69 49.3 102.6 4.7-1.6 9.3-3.3 13.5-5-7.3-6.2-11.7-15.9-12-25.3-16.6-4.1-20.8-29.4-17.2-41.6-9.5-7-13.6-18.1-14.2-28.7-7.2.7-13.6-.1-19.4-2m103.3 10.4c-13.6 8.1-27.6 14.3-41.6 19.2l-9 4.4c-4.4 4.6-4.5 15.6 1 20.3 9.7 3.5 50.1-21.2 57.7-27.9 1.3-5.8-3.5-14.9-8.1-16m-178.1 21.2L19.1 335.8V495c95.7-25.3 146.9-47.1 209.7-90.2-10.8-38-20.5-74.8-44.3-107.5m187.8 16.9c-13.6 10.2-27.2 16.2-42.9 21.4-.4 6.7 2.5 18.1 12.1 16 19.9-4.3 36.3-17.5 36.6-24.6.2-3.7-2-10.5-5.8-12.8m60.806 174.703c-13.615 9.57-27.616 17.728-42.816 25.284l13.217 23.347 39.3-18.8zm20.6 44.531-44.1 21.1 3.1 21.9c15.1.5 46.6-11.3 55.4-26.1-4.676-5.663-9.592-11.29-14.4-16.9"
      style={{
        fill: props.color ?? "#000",
        fillOpacity: 1,
      }}
      transform="translate(3.035 2.784) scale(.8411)"
    />
    <path
      d="m141.56 494.644 87.864-91.119s41.501 1.804 67.254 47.73c-2.432 15.66-7.955 33.807-14.102 43.39zm98.169-103.594c22.051 3.732 50.465 20.194 65.243 47.319 31.73-15.194 42.085-20.937 57.733-34.12 8.647-7.285 20.478-15.953 21.574-27.316.983-10.186-7.505-12.393-7.505-12.393s5.358-11.703 1.802-17.991c-3.636-6.432-6.886-8.415-14.276-7.166 4.397-5.767 2.354-15.448-1.042-20.534-4.474-5.93-12.545-2.219-12.545-2.219 2.047-4.775 2.7-10.337-2.641-18.84-4.62-7.354-17.524-2.052-26.034-.318-23.93 4.878-63.869 33.375-63.869 33.375z"
      style={{
        fill: props.color ?? "#000",
        stroke: "none",
        strokeWidth: 1,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
        fillOpacity: 1,
      }}
    />
  </svg>
)
export default CkSwordTwoHanded
